import axios from "axios";

const userStore = {
  state: {
    users: [],
    user: [],
    errors: [],
    check: 0,
    auth: false,
    errors_editUser: [],
  },
  actions: {
    async doLogin({ commit }, user) {
      try {
        const response = await axios.get("/sanctum/csrf-cookie");
        const response2 = await axios.post("/api/login", user);
        localStorage.setItem("user_token", response2.data.token);
        localStorage.setItem("user", JSON.stringify(response2.data.user));
        commit("setUser", response2.data);
        return true;
      } catch (error) {
        commit("setErrors", error.response.data);
        return false;
      }
    },
    async doLogout({ commit }) {
      try {
        const token = localStorage.getItem("user_token");
        axios.defaults.headers[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("user_token")}`;
        //console.log(`Bearer ${localStorage.getItem('user_token')}`);
        const header = `Authorization: Bearer ${token}`;
        const response = await axios.post("/api/logout");
        commit("logout", response.data.data);
      } catch (error) {}
    },
    async saveMyUser({ commit }, users) {
      try {
        const response = await axios.post("/api/users", users);
        commit("saveMyUser", response.data);
        return true;
      } catch (error) {
        commit("setErrors", error.response.data);
        return false;
      }
    },
    async saveMyUserAddresss1({ commit }, users) {
      let p = new FormData();
      for (const prop in users) {
        p.append(prop, users[prop]);
      }
      try {
        const response = await axios.post("/api/user/saveaddress1", p);
        commit("saveAddress", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async saveMyUserAddresss2({ commit }, users) {
      let p = new FormData();
      for (const prop in users) {
        p.append(prop, users[prop]);
      }
      try {
        const response = await axios.post("/api/user/saveaddress2", p);
        commit("saveAddress", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async getUsers({ commit }) {
      try {
        //const response = await axios.get('https://server.madec.com.mx/api/myusers');
        const response = await axios.get("/api/users");
        commit("setUsers", response.data);
      } catch (error) {}
    },
    async getUser({ commit }, user) {
      try {
        //const response = await axios.get('https://server.madec.com.mx/api/myusers');
        const response = await axios.get("/api/users/" + user.id);
        commit("setUser1", response.data);
        return response.data;
      } catch (error) {}
    },
    async editUser({ commit }, user) {
      try {
        const response = await axios.put("/api/users/" + user.id, user);
        localStorage.setItem("user", JSON.stringify(response.data));
        commit("editUser", response.data);
      } catch (error) {
        commit("setErrorsEditUser", error.response.data);
      }
    },
    async editUsers({ commit }, user) {
      try {
        const response = await axios.put("/api/users/" + user.id, user);
        commit("setErrorsEditUser", []);
      } catch (error) {
        commit("setErrorsEditUser", error.response.data);
      }
    },
    async accesoEmailUser({ commit }, user) {
      try {
        const response = await axios.post("api/user/acceso", user);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async checkPassword({ commit }, user) {
      try {
        //const response = await axios.get('https://server.madec.com.mx/api/myusers');
        const response = await axios.get("/api/user/checkpassword/" + user.id, {
          params: {
            password: user.password,
          },
        });
        commit("checkUsers", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async updatePassword({ commit }, user) {
      try {
        const response = await axios.put(
          "/api/user/updatepassword/" + user.id,
          user
        );
        commit("editPass", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async cambioemail({ commit }, email) {
      try {
        const response = await axios.post("/api/user/cambio", email);
        commit("editPass", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async clearUserErrors({ commit }) {
      try {
        commit("clearErrors");
      } catch (error) {}
    },
    async updateEst({ commit }, user) {
      try {
        const response = await axios.put("/api/user/estatus/" + user.id, user);
        commit("editUser1", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async regImage({ commit }, user) {
      let p = new FormData();
      for (const prop in user) {
        p.append(prop, user[prop]);
      }
      try {
        const response = await axios.post("/api/images/register/logo", p);
        localStorage.setItem("user", JSON.stringify(response.data));
        commit("setUser1", response.data);
        return true;
      } catch (error) {
        commit("setErrors", error.response.data);
        return false;
      }
    },
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
      state.errors = [];
    },
    checkUsers(state, data) {
      state.check = data;
      state.errors = [];
    },
    setUser(state, data) {
      state.user = data.user;
      state.errors = [];
      state.auth = true;
    },
    setUser1(state, user) {
      state.user = user;
      state.errors = [];
      state.auth = true;
    },
    saveMyUser(state, users) {
      state.user.push(users);
      state.errors = [];
    },
    saveAddress(state, users) {
      state.user.push(users);
      state.errors = [];
    },
    setErrors(state, errors) {
      state.errors = errors;
      state.check = 0;
    },
    setErrorsEditUser(state, errors) {
      state.errors_editUser = errors;
    },
    editUser(state, user) {
      let p = state.user.find((p) => p.id === user.id);
      Object.assign(p, user);
      state.errors_editUser = [];
    },
    editUser1(state, user) {
      let p = state.users.find((p) => p.id === user.id);
      Object.assign(p, user);
    },
    clearErrors(state) {
      state.errors = [];
      state.errors_editUser = [];
    },
    editPass(state, user) {
      state.errors = [];
    },
    logout() {
      state.auth = false;
    },
  },
};
export default userStore;
